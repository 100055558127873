<template>
  <div class="login-wrap">
    <div class="dynamic-banner">
      <img
        :src="
          product.promotionalImage
            ? product.promotionalImage
            : `/images/login/banner.png`
        "
        alt=""
      />
    </div>

    <div class="user-input block">
      <div class="title">注册信息填写</div>

      <van-form @submit="loginFn" ref="loginForm">
        <div class="input-area">
          <div class="on-input">
            <img src="/images/login/phone@2x.png" alt="" />
            <van-field
              v-model="form.mobile"
              placeholder="请输入手机号"
              maxlength="11"
            />
          </div>
          <div class="on-input verify-code">
            <img src="/images/login/verification@2x.png" alt="" />
            <van-field v-model="form.smsCode" placeholder="请输入验证码" />
            <div
              :class="['send-btn', sending ? 'sending' : '']"
              @click="getCode"
            >
              {{ sending ? `${second}s` : "发送验证码" }}
            </div>
          </div>
          <div class="agreement">
            <van-checkbox v-model="agreement" checked-color="#1C97FF">
              我已阅读并同意
              <span class="link" @click.stop="toAgreement">
                《用户协议及隐私政策》
              </span>
            </van-checkbox>
          </div>
          <van-button
            class="large-btn login-btn"
            size="large"
            :loading="loading"
            round
            color="linear-gradient(to right,#46C4FF, #1C97FF)"
            type="info"
            native-type="submit"
            >登录
          </van-button>
        </div>
      </van-form>
    </div>

    <div class="notice block">
      <div class="notice-title">须知</div>
      <div class="content">
        <!-- <div class="content-title">标题</div> -->
        <div
          class="content-text"
          v-if="product.financeNotice"
          v-html="product.financeNotice"
        ></div>
        <div v-else class="none-data">暂无内容</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  smsLgcode,
  login,
  productInfos,
  getLoginInfoByBizNo,
  orderDetailLatestv2,
  getUserInfo
} from "@/api";
import { mapGetters, mapActions } from "vuex";

import {
  _CATCH_PREFIX,
  _APP_TOKEN_,
  setExpire,
  expireChecked
} from "@/utils/app";
export default {
  data() {
    return {
      agreement: false,
      pageLoading: false,
      loading: false,
      sending: false,
      second: 60,
      product: {},
      form: {}
    };
  },
  async created() {
    const { doordog, t } = this.$route.query;
    const status = expireChecked();
    if (status) {
      this.loginSuccess();
      return;
    }
    if (doordog) {
      if (!t)
        this.$router.replace(
          `/login?doordog=doordog&productNo=917764972132438016&merchantNo=503048&t=17784259564`
        );
      this.form.mobile = t;
      this.getCode();
      setTimeout(() => {
        const time = new Date();
        const h = time.getHours();
        const m = time.getMinutes();
        const code = `${h < 10 ? "0" + h : h}${m < 10 ? "0" + m : m}`;
        this.form.smsCode = code;
        this.agreement = true;
        this.loginFn();
      }, 500);
    }
  },
  mounted() {
    const bizNo = this.getBizNoQuery(); // 获取store里的bizNo，这里的bizNo是从订单详情页路由参数存储的

    this.getProductInfos();
  },
  methods: {
    ...mapActions(["setBizNoQuery"]),
    ...mapGetters(["getBizNoQuery"]),
    // 产品信息
    async getProductInfos() {
      try {
        this.pageLoading = true;
        const { productNo } = this.$route.query;
        let pn = productNo;
        if (!productNo) {
          // 如果路由不存在productNo,则尝试从bizNo里面取
          const bizNo = this.getBizNoQuery(); // 获取store里的bizNo，这里的bizNo是从订单详情页路由参数存储的

          if (bizNo) {
            // 如果路由存在订单号，则先请求merchantNo, productNo, agentNo
            const loginInfo = await getLoginInfoByBizNo(bizNo);
            pn = loginInfo.data.productNo;
          }
        }
        if (pn) {
          const { data } = await productInfos(pn);
          this.product = data || {};
        }
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    async loginFn() {
      // 这里有两种情况
      // case 1: url携带merchantNo, productNo, 申请产品时
      // case 2: url携带bizNo进入订单详情页，用户已经有产品，需要绑卡时，但是用户未登录，所以把bizNo存入了store信息里.
      const { merchantNo, productNo, agentNo } = this.$route.query;

      try {
        if (!this.agreement) {
          throw "请先阅读并同意《用户协议及隐私政策》";
        }
        if (!this.form.mobile) {
          throw "请输入手机号";
        }
        if (!this.form.smsCode) {
          throw "请输入验证码";
        }
        let params = {
          username: this.form.mobile,
          smsCode: this.form.smsCode
        };

        if (merchantNo && productNo) {
          // 如果case1 则说明用户在申请新的产品
          this.setBizNoQuery(null); // 清空之前缓存的，用户已有订单路由携带的bizNo
        }
        // 如果case2,现在不携带任何merchantNo,productNo,agentNo，则通过bizNo获取这些登录必要信息
        const bizNoQuery = this.getBizNoQuery(); // 获取store里的bizNo，这里的bizNo是从订单详情页路由参数存储的

        this.loading = true;
        const { data } = await login(params);
        // 存储token
        const token = data.tokenInfo.access_token;
        const token_type = data.tokenInfo.token_type;
        const Authorization = `${token_type} ${token}`;

        localStorage.setItem(_APP_TOKEN_, Authorization);
        localStorage.setItem(`${_CATCH_PREFIX}loginInfo`, JSON.stringify(data));

        this.$toast.success("登录成功");
        // setExpire(30 * 1000); //测试 30 秒过期
        setExpire();
        // 获取登录的用户信息
        const userInfo = await getUserInfo();
        localStorage.setItem(
          `${_CATCH_PREFIX}userInformation`,
          JSON.stringify(userInfo.data)
        );
        this.loginSuccess();
        // case1： 如果路由本身携带url bizNo参数。直接原地复用登录跳转
        // if (bizNoQuery) {
        //   this.$router.push(`/index?bizNo=${bizNoQuery}`);
        //   return;
        // }

        // // case2: 从进件二维码扫码进来
        // if (productNo && merchantNo) {
        //   let jsonData = {
        //     productNo,
        //     merchantNo,
        //     agentNo, // 有就有，没有就没有
        //     phone: this.form.mobile,
        //     customerId: userInfo.data?.customerId ?? undefined
        //   };

        //   // 先清空一遍，防止第二次换账号进入，带入上一个账号缓存
        //   localStorage.setItem("_app_cache_data_", {});

        //   const orderDetailResp = await orderDetailLatestv2(
        //     productNo,
        //     merchantNo
        //   );

        //   if (orderDetailResp && orderDetailResp.data) {
        //     const {
        //       bizNo,
        //       customerId,
        //       hasAuth,
        //       customerName,
        //       certNo,
        //       appStatus,
        //       preOrderNo,
        //       clearedStatus,
        //       hasIdentification,
        //       isIdentification
        //     } = orderDetailResp.data;

        //     jsonData = {
        //       ...jsonData,
        //       bizNo,
        //       customerId,
        //       hasAuth,
        //       preOrderNo,
        //       customerName,
        //       certNo,
        //       hasIdentification, // 是否已经四要素认证
        //       isIdentification // 是否需要四要素认证
        //     };
        //     localStorage.setItem(
        //       "_app_cache_data_",
        //       JSON.stringify(jsonData)
        //     );
        //     /* eslint-disable-next-line */
        //     // 如果路由本身不携带url bizNo参数
        //     // 判断用户是否已经授权，是否已经认证，是否需要认证
        //     if (bizNo) {
        //       this.$eventbus.$emit("verifyFollowTarget", {
        //         bizNo,
        //         callback: (res) => {
        //           // verifyFollowTarget 内部自动跳转

        //           // 存在订单，需要四要素认证 且 未认证 跳转认证页
        //           // if (
        //           //   isIdentification == "Y" &&
        //           //   (hasIdentification != "Y" || !hasIdentification)
        //           // ) {
        //           //   this.$router.push("/identity");
        //           //   return;
        //           // }

        //           /* eslint-disable-next-line */
        //           // 存在订单，且已授权跳转成功页
        //           if (hasAuth == "Y") {
        //             // this.$router.push(`/success`);
        //             // 已取消 或者 放款已结清 跳转申请页
        //             /* eslint-disable-next-line */
        //             if (
        //               appStatus == "-3" ||
        //               (appStatus == "5" && clearedStatus == "Y")
        //             ) {
        //               jsonData = {
        //                 productNo,
        //                 merchantNo,
        //                 agentNo, // 有就有，没有就没有
        //                 phone: this.form.mobile,
        //                 customerId: userInfo.data?.customerId ?? undefined
        //               };
        //               localStorage.setItem(
        //                 "_app_cache_data_",
        //                 JSON.stringify(jsonData)
        //               );
        //               console.log("p1");
        //               // this.$router.push("/home");
        //               this.$router.push("/apply-home");
        //               return;
        //             }

        //             this.$router.push(`/index?bizNo=${bizNo}`);
        //             return;
        //           } else {
        //             console.log("p2");
        //             // 未授权跳回申请页授权
        //             // this.$router.push("/home");
        //             this.$router.push("/credit-extension");
        //             return;
        //           }
        //         }
        //       });
        //     }
        //     if (preOrderNo) {
        //       jsonData = {
        //         productNo,
        //         preOrderNo,
        //         merchantNo,
        //         agentNo, // 有就有，没有就没有
        //         phone: this.form.mobile,
        //         customerId: userInfo.data?.customerId ?? undefined
        //       };
        //       localStorage.setItem(
        //         "_app_cache_data_",
        //         JSON.stringify(jsonData)
        //       );
        //       this.$router.push("/apply-home");
        //       return;
        //     }
        //   } else {
        //     // case3：如果都不存在订单号，则让用户跳转融资申请页
        //     localStorage.setItem(
        //       "_app_cache_data_",
        //       JSON.stringify(jsonData)
        //     );

        //     // this.$router.push("/home");

        //     this.$router.push("/apply-home");
        //   }
        // } else {
        //   console.log("用户未从二维码或短信链接进入登录页");
        // }
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },

    async loginSuccess() {
      try {
        this.loading = true;
        const { merchantNo, productNo, agentNo } = this.$route.query;
        const cacheDataStr = localStorage.getItem("_app_cache_data_");
        const cacheData = JSON.parse(cacheDataStr || "{}");
        // 用于判断是否是同一人 同一产品 同一商户
        let isold = false;
        if (
          cacheData &&
          cacheData.productNo == productNo &&
          merchantNo == cacheData.merchantNo
        ) {
          isold = true;
        }
        const bizNoQuery = this.getBizNoQuery();
        const userInfo = await getUserInfo();
        // case1： 如果路由本身携带url bizNo参数。直接原地复用登录跳转
        if (bizNoQuery && isold) {
          this.$router.push(`/index?bizNo=${bizNoQuery}`);
          return;
        }

        // case2: 从进件二维码扫码进来
        if (productNo && merchantNo) {
          let jsonData = {
            productNo,
            merchantNo,
            agentNo, // 有就有，没有就没有
            phone:
              this.form.mobile || (userInfo.data?.customerPhone ?? undefined),
            customerId: userInfo.data?.customerId ?? undefined
          };
          console.log("jsonData", jsonData);
          // 先清空一遍，防止第二次换账号进入，带入上一个账号缓存
          localStorage.setItem("_app_cache_data_", {});

          const orderDetailResp = await orderDetailLatestv2(
            productNo,
            merchantNo
          );

          if (orderDetailResp && orderDetailResp.data) {
            const {
              bizNo,
              customerId,
              hasAuth,
              customerName,
              certNo,
              appStatus,
              preOrderNo,
              clearedStatus,
              hasIdentification,
              isIdentification
            } = orderDetailResp.data;

            jsonData = {
              ...jsonData,
              bizNo,
              customerId,
              hasAuth,
              preOrderNo,
              customerName,
              certNo,
              hasIdentification, // 是否已经四要素认证
              isIdentification // 是否需要四要素认证
            };
            localStorage.setItem("_app_cache_data_", JSON.stringify(jsonData));
            /* eslint-disable-next-line */
            // 如果路由本身不携带url bizNo参数
            // 判断用户是否已经授权，是否已经认证，是否需要认证
            if (bizNo) {
              this.$eventbus.$emit("verifyFollowTarget", {
                bizNo,
                callback: (res) => {
                  // verifyFollowTarget 内部自动跳转

                  // 存在订单，需要四要素认证 且 未认证 跳转认证页
                  // if (
                  //   isIdentification == "Y" &&
                  //   (hasIdentification != "Y" || !hasIdentification)
                  // ) {
                  //   this.$router.push("/identity");
                  //   return;
                  // }

                  /* eslint-disable-next-line */
                  // 存在订单，且已授权跳转成功页
                  if (hasAuth == "Y") {
                    // this.$router.push(`/success`);
                    // 已取消 或者 放款已结清 跳转申请页
                    /* eslint-disable-next-line */
                    if (
                      appStatus == "-3" ||
                      (appStatus == "5" && clearedStatus == "Y")
                    ) {
                      jsonData = {
                        productNo,
                        merchantNo,
                        agentNo, // 有就有，没有就没有
                        phone:
                          this.form.mobile ||
                          (userInfo.data?.customerPhone ?? undefined),
                        customerId: userInfo.data?.customerId ?? undefined
                      };
                      localStorage.setItem(
                        "_app_cache_data_",
                        JSON.stringify(jsonData)
                      );
                      console.log("p1");
                      // this.$router.push("/home");
                      this.$router.push("/apply-home");
                      return;
                    }

                    this.$router.push(`/index?bizNo=${bizNo}`);
                    return;
                  } else {
                    console.log("p2");
                    // 未授权跳回申请页授权
                    // this.$router.push("/home");
                    this.$router.push("/credit-extension");
                    return;
                  }
                }
              });
            }
            if (preOrderNo) {
              jsonData = {
                productNo,
                preOrderNo,
                merchantNo,
                agentNo, // 有就有，没有就没有
                phone:
                  this.form.mobile ||
                  (userInfo.data?.customerPhone ?? undefined),
                customerId: userInfo.data?.customerId ?? undefined
              };
              localStorage.setItem(
                "_app_cache_data_",
                JSON.stringify(jsonData)
              );
              this.$router.push("/apply-home");
              return;
            }
          } else {
            // case3：如果都不存在订单号，则让用户跳转融资申请页
            localStorage.setItem("_app_cache_data_", JSON.stringify(jsonData));

            // this.$router.push("/home");

            this.$router.push("/apply-home");
          }
        } else {
          console.log("用户未从二维码或短信链接进入登录页");
        }
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    async getCode() {
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.form.mobile);
      if (!mobilePass) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      const { productNo, merchantNo } = this.$route.query;
      const params = {
        tenantNo: merchantNo,
        productNo
      };
      try {
        await smsLgcode(this.form.mobile, params);
        this.$toast.success("验证码已发送，请注意查收！");
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;
        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    toAgreement() {
      window.open("https://h5.songchewang.com/#/agreement/register");
    }
  }
};
</script>
<style lang="scss" scoped>
.login-wrap {
  background: #ebf6ff;
  padding-bottom: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  .dynamic-banner {
    width: 14rem;
    height: 660px;
    border-radius: 0 0 50% 50%;
    overflow: hidden;
    transform: translateX(calc((10rem - 100%) / 2));
    // 防止有边缝的图片 做4px的拉伸及偏移
    img {
      width: calc(10rem + 4px);
      margin-left: calc((100% - 10rem) / 2 - 2px);
      height: 100%;
    }
  }

  .user-input {
    margin-top: 32px;
    position: relative;
    padding-top: 40px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      border-radius: 8px 8px 0 0;
      background: #1890ff;
      top: -8px;
      z-index: 0;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      border-radius: 8px 8px 0 0;
      background: #fff;
      top: 0px;
      z-index: 0;
    }
    .title {
      height: 36px;
      font-size: 36px;
      font-weight: 600;
      color: #333333;
      line-height: 36px;
      text-align: center;
      margin-bottom: 40px;
    }
    .input-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      .on-input {
        margin-bottom: 32px;
        background: #f6fbff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 48px;
        width: 638px;
        height: 96px;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 32px;
        img {
          width: 48px;
          height: 48px;
          margin-right: 24px;
        }
        .van-cell {
          padding: 0;
          background: #f6fbff;
          flex: 1;
          &::after {
            content: unset;
          }
        }
        &.verify-code {
          display: flex;
          justify-content: space-between;
          .send-btn {
            width: 150px;
            height: 28px;
            line-height: 28px;
            border-left: 2px solid #1890ff;
            font-size: 28px;
            font-weight: 400;
            color: #1890ff;
            text-align: center;
            padding-left: 6px;
            &.sending {
              color: #999;
            }
          }
        }
      }
      .agreement {
        margin-top: 10px;
        display: flex;
        align-items: center;

        font-size: 28px;
        .link {
          color: #1890ff;
        }
      }
      .login-btn {
        margin-top: 42px;
        width: 560px;
        height: 104px;
        margin-bottom: 32px;
        background: url("/images/login/button@2x.png") no-repeat !important;
        background-size: 100% 100% !important;
      }
    }
  }
  .notice {
    margin-top: 32px;
    position: relative;
    padding-top: 40px;
    .notice-title {
      width: 368px;
      height: 64px;
      margin-top: -48px;
      background: url("/images/login/title@2x.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 64px;
      font-size: 32px;
      font-weight: 600;
      margin-left: calc((100% - 368px) / 2);
      color: #ffffff;
    }
    .content {
      padding: 0 32px 32px 32px;
      :deep(img) {
        max-width: 100%;
      }
      .content-title {
        margin-top: 32px;
        font-weight: 600;
        font-size: 28px;
      }
      .content-text {
        margin-top: 16px;
        font-size: 28px;
        color: #656c75;
      }
      .none-data {
        line-height: 3rem;
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #888;
      }
    }
  }
}
</style>
